<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1">
      <h1 class="uk-heading-line">
        {{ headerTitle }}
      </h1>
    </div>
    <create
      :images="images"
      :list-role="listRole"
      :list-partner="listPartner"
      :list-facility-type="listFacilityType"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :set-data-modal-add-edit="setDataModalAddEdit"
    />
    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalSave"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from "@/utils/constant"
import Create from './Create'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'
import getHeaderTitle from '@/utils/header-title'

export default {
  components: {
    Create,
    ModalAddEditDiscard
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      listRole: 'role/list_role',
      listPartner: 'partner/list_partner',
      listFacilityType: 'masterData/list_master_data',
      getDataModalAddEdit: 'user/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'user/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    await this.actionGetListMasterData({data_type: 'facility_types'})
    await this.actionGetListRole({page: 1, limit: 10})
    await this.actionGetListPartner({page: 1, limit: 10})
  },
  methods: {
    ...mapActions({
      actionGetListMasterData: 'masterData/getListMasterData',
      actionGetListRole: 'role/getListRole',
      actionGetListPartner: 'partner/getListPartner',
      createUser: 'user/createUser'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'user/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'user/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleModalSave() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        type: 'save',
        loadingTable: false,
        loadingPopUp: true
      })
      this.createUser(this.getDataModalAddEdit).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            notificationSuccess('Add User Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'User' })
            this.setToggleModalAddEditDiscard({
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              customTitleBtnOk: '',
              customTitleBtnCancel: '',
              customClass: '',
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        } else {
          this.setToggleModalAddEditDiscard({
            targetModals: false,  
            title: '',
            description: '',
            type: '',
            customTitleBtnOk: '',
            customTitleBtnCancel: '',
            customClass: '',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>
